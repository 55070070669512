<template>
  <div class="vm">
    <div class="vm__vision">
      <h2>Vision</h2>
      <p>
        {{vam.vision}}
      </p>
      <div class="vm__decoration">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="vm__mission">
      <h2>Mission</h2>
      <p>
        {{ vam.mission }}
      </p>
      <div class="vm__decoration">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VisionMission",
  props:{
    vam: String
  }
};
</script>

<style lang="scss" scoped>
.vm {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 100%;

  &__vision,
  &__mission {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;

    h2 {
      text-decoration: none;
      color: #da9100;
      text-align: center;
      font-size: 2.4rem;
      font-weight: bold;
    }

    p {
      text-align: center;
      margin-bottom: 2.5rem;

      span{
          font-style: italic;
      }
    }
  }

  &__decoration {
    display: flex;
    justify-content: center;
    width: 100%;

    div {
      background-color: #da9100;
      min-width: 5px;
      height: 5px;
      margin: 0px 0.2rem;
    }

    div:nth-child(4) {
      width: 100px;
    }
  }
}
</style>
