<template>
    <div class="about">
        <page-header title="About"/>
        <section id="soj">
            <card :thumb="soj" title="Sound Of Jesus" :cardText="$store.state.about.filter( sec => sec.id === 'soj' )[0].description"/>
        </section>
        <section id="vision_mission">
            <img src="~@/assets/img/bacg.png" alt="">
            <vision-mission :vam="$store.state.about.filter( sec => sec.id === 'vam' )[0]"/>
        </section>
        <section id="kla">
            <card :thumb="$store.state.about.filter( sec => sec.id === 'fkr' )[0].file" title="Fr. Kalana Inshara Peiris" direction="right"
                  :card-text="$store.state.about.filter( sec => sec.id === 'fkr' )[0].text"/>
        </section>
        <section id="chru">
            <card :thumb="$store.state.about.filter( sec => sec.id === 'tcc' )[0].file"
                  :title="catholic.title" :card-text="$store.state.about.filter( sec => sec.id === 'tcc' )[0].text"/>
        </section>
    </div>
</template>

<script>

    import PageHeader from "../components/comm/PageHeader"
    import Card from "../components/comm/Card";
    import VisionMission from "../components/about/VisionMission";
    import AOS from 'aos';
    import 'aos/dist/aos.css';

    export default {
        name: "About",
        components: {VisionMission, Card, PageHeader},
        mounted() {
            document.querySelectorAll("section").forEach(section => {
                section.setAttribute("data-aos", "fade-up")
            });
            AOS.init()
        },
        data() {
            return {
                soj: `<div class='embed-container'>
                         <iframe src='https://www.youtube.com/embed/ubDcmIUMcLo' frameborder='0' allowfullscreen></iframe>
                      </div>`,
                kalana: {
                    about: 'Fr. Kalana Inshara Peiris was born on the 12th August 1978 in Kalutara, Sri Lanka to Mrs. Kanthi \n' +
                        'Peiris and Late Dr. Charles Peiris as their second child and is the brother of Inshira (sister), \n' +
                        'Inshika (sister) and Inshaka (brother). He had his primary and secondary education at St. Peter’s \n' +
                        'College Colombo 04 before entering to St. Aloysius Seminary Borella in 1995, after humbly \n' +
                        'accepting the call of God to be a shepherd to his flock. Thereafter to the National Seminary \n' +
                        'Kandy, to complete his formation to be a priest and was ordained on 12th of January 2008.'
                },
                catholic: {
                    desc: "The Catholic Church founded by Jesus Christ in 33AD, sometimes referred to as the Roman Catholic Church, is the largest Christian church, with approximately 1.3 billion baptisedCatholics worldwide as of 2018. As the world's oldest and largest continuously functioning international institution,it has played a prominent role in the history and development of Western civilization.The church is headedby the Bishop of Rome, known as the pope. Its central administration is the Holy See.The Christian beliefs of Catholicism are found in the Nicene Creed. The Catholic Church teaches that it is the One, Holy, Catholic and Apostolicchurch founded by Jesus Christin his Great Commission, that its bishopsare the successorsof Christ's apostles, and that the pope is the successorto Saint Peterupon whom primacywas conferred by Jesus Christ. It maintains that it practises the original Christian faith, reserving infallibility, passed down by sacred tradition. The Latin Church, the twenty-three Eastern Catholic Churches, and institutessuch as mendicant orders, enclosed monastic ordersand third ordersreflect a varietyof theologicaland spiritual emphases in the church.Of its seven sacramentsthe Eucharistis the principal one, celebrated liturgicallyin the Mass. The church teaches that through consecrationby a priestthe sacrificial bread and wine become the body and blood of Christ. The Virgin Mary is venerated in the Catholic Church as Mother of God and Queen of Heaven, honoured in dogmasand devotions. Its teaching includes Divine Mercy, sanctification through faith and evangelization of the Gospelas well as Catholic social teaching, which emphasises voluntary support for the sick, the poor, and the afflicted through the corporal and spiritual works of mercy. The Catholic Church is the largest non-government provider of educationand health carein the world.",
                    title: "The Catholic Church"
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    #kla {
        background-color: #f0f0f0;
    }

    #soj {
        margin-top: 4rem;
    }

    #vision_mission, #vision_mission, #kla, #chru {
        position: relative;

        img {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
</style>
